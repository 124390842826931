<div class="group-header no-padding bottom20">
  <div class="flex no-padding bottom20">
    <div class="header-title">Rate Calculator</div>
    <div class="left20">
      <input nz-input placeholder="From Zip-code" class="input-zipcode" [(ngModel)]="fromZipCode" (keypress)="onlyNumberKey($event)">
    </div>
    <div class="left20" style="align-self: center;">-</div>
    <div class="left20">
      <input nz-input  placeholder="To Zip-code" class="input-zipcode" [(ngModel)]="toZipCode" (keypress)="onlyNumberKey($event)">
    </div>
    <button nz-button  class="btn-calculate left20" [disabled]="!isEnableBtnCalculate" (click)="onBtnCalculate()">{{getBtnLabel}}</button>
  </div>

  <div *ngIf="isCalculate" class="list-body top10">
    <div *ngIf="!isListDataNotEmpty">
      <i nz-icon nzType="info-circle" nzTheme="outline" class="icon-info-not-found"></i> No rate found for these zips.
    </div>
    <ng-container *ngIf="isListDataNotEmpty">
      <nz-table
        #nzTable nzBordered="true"
        [nzData]="listData" nzSize="small"
        [nzPageSize]="limit"
        [nzLoading]="isLoading"
        [nzLoadingIndicator]="tplLoading"
        [nzNoResult]="tplNoData"
        [nzSize]="'default'">
        <ng-template #tplNoData>
          <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
        </ng-template>
        <ng-template #tplLoading>
          <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
        </ng-template>
        <thead>
          <tr>
            <th nzWidth="200px">Base Rate</th>
            <th>Transit Day</th>
            <th>Rate File Name</th>
            <th>Upload Date & Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of nzTable.data; let i = index">
            <td class="base-rate-column">{{getBaseRate(item)}}</td>
            <td>{{getTransitDay(item)}}</td>
            <td>{{item.zipRateUploadFile?.name}}</td>
            <td>{{formatDate(item.insert?.when)}}</td>
            <td><span (click)="onBtnDownload(item.zipRateUploadFile)" class="clickable" style="color: #1802D0;">Download Rate File</span></td>
          </tr>
        </tbody>
      </nz-table>
    </ng-container>
  </div>
</div>
