import { Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { BaseFormItem } from "@app/admin/base/form-item";
import { Const } from "@const/Const";
import { InputHelper } from "@services/input-helper";
import { Log } from "@services/log";
import { MasterData } from "@services/master.data";
import { Utils } from "@services/utils";

@Component({
  selector: "[service-option-rate]",
  templateUrl: './index.html',
  styleUrls: ['../../detail.scss', './index.scss'],
})
export class ServiceOptionRate extends BaseFormItem {
  
  public isError = false;
  public isLoading = false;

  constructor() {
    super();
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  get isCreateNew(): boolean {
    return !this.model;
  }

  get shouldShowForm() {
    return this.model;
  }

  public allServiceOptions = []

  ngOnInit(): void {
    this.allServiceOptions = [
      ...MasterData.ShipmentServiceOptionsPickup,
      ...MasterData.ShipmentServiceOptionsDelivery,
      ...MasterData.ShipmentServiceOptionsAddition,
    ];
    for (let item of this.allServiceOptions) {
      this.formGroupDeclaration[item._id] = {
        label: item.name,
        getValue: InputHelper.getValueMoney, 
        formatValue: InputHelper.formatMoney2
      }
    }
    this.getData();
    super.ngOnInit();
  }

  private getDataDone(err = null) {
    if (err) {
      this.showErr(err);
      this.isError = true;
    } else {
      this.createFormInput(this.model);
      this.setEnableFormGroup(false);
    }
    this.isLoading = false;
  }

  private getData() {
    this.isLoading = true;
    this.api.GET(Const.APIURI_SERVICE_OPTIONS).subscribe(
      resp => {
        Log.d('getData done ', resp);
        let list_data = resp.data.list_data;
        this.model = {};
        if (Utils.isArrayNotEmpty(list_data)) {
          for (let item of list_data) {
            if (item?.id) this.model[item.id] = item.rate || 0;
          }
        }
        this.getDataDone();
      }, err => {
        this.getDataDone(err);
      }
    );
  }

  onBtnSave() {
    let data = this.getFormData_JSON(this.isCreateNew);
    let url = `${Const.APIURI_SERVICE_OPTIONS}/update_rate`;
    this.api.POST(url, data).subscribe(
      resp => {
        this.onUpdateSuccess(resp);
        this.getData();
        this.stopEditing();
      }, err => {
        this.showErr(err);
        this.stopEditing();
      }
    );
  }

  onBtnDiscardChanges() {
    this.createFormInput(this.model);
    this.stopEditing();
  }

  protected stopEditing() {
    this.isEditing = false;
    this.setEnableFormGroup(false);
  }

  get isModelChanged() {
    return this.isFormDataChanged();
  }

  onInputChanged(event, key) {
    return InputHelper.handleInputChangeMoney(event, <FormControl>this.formInput.get(key), true);
  }

  onInputKeyPress(event, key) {
    return InputHelper.handleInputKeyPressMoney(event);
  }
  

}