import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from "swiper/angular";
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzAlertModule } from 'ng-zorro-antd/alert';

// import { PublicComponent } from './comp';
// import { PublicRoutingModule } from './routing';
// import { LandingPageHeaderModule } from './header/module';
// import { TopComponent } from './toppage/comp';
// import { PublicShipperModule } from './ship-with-us/module';
// import { LandingPageFooterModule } from './footer/module';
// import { PublicCarrierModule } from './carrier/module';
// import { BlogModule } from './blog/module';
import { AppDownload } from './comp';
import { SettingVersionModule } from '@app/admin/settings/version/module';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    NzIconModule,
    NzButtonModule,
    NzCollapseModule,
    NzTableModule,
    NzAlertModule,
    SettingVersionModule,   

    // PublicRoutingModule,
    // LandingPageHeaderModule,
    // LandingPageFooterModule,
    // PublicShipperModule,
    // PublicCarrierModule,
    // BlogModule
    SwiperModule,
  ],
  declarations: [
    // PublicComponent,
    // TopComponent,    
    AppDownload,
  ],
  exports: [
  ],
  providers: [
  ]
})
export class AppDownloadModule {}