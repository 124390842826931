<div>
  <div class="flex-space-between no-padding bottom20">
    <div class="group-header">
      Service Options
    </div>
    <ng-container *ngIf="requirePermissions([PermissionCode.zip_rate.update])">
      <div *ngIf="!isLoading && !isError">
        <button nz-button (click)="onBtnEdit()" nzType="primary" *ngIf="!isEditing">Update</button>
        <ng-container *ngIf="isEditing">
          <button nz-button (click)="onBtnDiscardChanges()" nzType="defaul">Discard Changes</button>
          <button nz-button (click)="onBtnSave()" nzType="primary" class="left20" [disabled]="!isModelChanged">Save Changes</button>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
  <div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

  <div *ngIf="!isLoading && shouldShowForm">
    <form nz-form [formGroup]="formInput" class="form-detail">
      <div nz-row [nzGutter]="{ md: 32 }">
        <div nz-col *ngFor="let key of formInputKeys" nzXs="24" nzMd="12">
          <div nz-row>
            <div nz-col nzXs="12">
              <div class="form-label-v2">
                {{getLabel(key)}}:
                <span *ngIf="isRequired(key)" class="label-mark-required"></span>
              </div>
            </div>
            <div nz-col nzXs="12">
              <nz-form-item>
                <nz-form-control>
                  <input nz-input [formControlName]="key" 
                    [type]="getInputType(key)" 
                    [placeholder]="getPlaceHolder(key)"
                    (input)="onInputChanged($event, key)"
                    (keypress)="onInputKeyPress($event, key)">
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
