import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Const } from '@const/Const';
import { BaseDetail } from '@app/admin/base/detail';

@Component({
  selector: 'app-download',
  templateUrl: './comp.html',
  // styleUrls: ['./style.scss', '../admin/detail.scss']
})
export class AppDownload extends BaseDetail {
  constructor(protected activatedRoute: ActivatedRoute) {
    super(activatedRoute);
  }
  data = {}

  ngOnInit(): void {
    super.ngOnInit();
    this.initData();
  }

  async initData(){
    this.api
    .GET(Const.APIURI_APP_DOWNLOAD)
    .subscribe(
      (response) => {
        this.data = response.data
      }
    );
  }

  public getHistories(key) {
    let histories = this.data?.[key]?.["histories"] || [];
    for (let index in histories) {
      const userId = histories[index]?.created?.by;
      histories[index]['byUser'] = this.data[key]['historyByUser']?.filter(u => u._id == userId)[0];
    }
    return histories
  }
}