<div style="padding: 24px; margin-top: 30px;">
  <!-- <div class="box-with-title">
    <div class="box-title"><span nz-icon nzType="android" nzTheme="outline"></span> App Crossdock Android</div>
    <div class="box-content"> -->
  <h3 style="margin-bottom: 20px;" nz-typography><span nz-icon nzType="android" nzTheme="outline"></span> App Crossdock Android</h3>
  <div class="dashboard-child-container no-padding">
    <div *ngIf="shouldShowFirstLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
    <setting-version-file-history [data]="getHistories('app_android_crossdock')" [showUploadedBy]="false">
    </setting-version-file-history>
  </div>
</div>
<!-- </div>
</div> -->