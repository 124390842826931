import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from "@services/date-utils";
import { Log } from "@services/log";
import { Utils } from "@services/utils";

@Component({
  selector: "[rate-calculator]",
  templateUrl: './index.html',
  styleUrls: ['../../detail.scss', './index.scss'],
})
export class RateCalculator extends BaseComponent {
  
  public fromZipCode;
  public toZipCode;
  public isLoading = false;
  public isCalculate = false;
  public listData = [];
  public limit = 3;

  constructor() {
    super();
  }

  get getBtnLabel() {
    return this.isCalculate ? 'Re-Calculate' : 'Calculate';
  }

  get isEnableBtnCalculate() {
    return this.fromZipCode && this.toZipCode ? true : false;
  }

  get isListDataNotEmpty(): boolean {
    return Utils.isArrayNotEmpty(this.listData);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onBtnCalculate() {
    if (!this.fromZipCode || !this.toZipCode) return;
    let url = `${Const.APIURI_ZIP_RATE}/calculate`;
    this.isCalculate = true;
    this.isLoading = true;
    let params = {
      fromZipCode : this.fromZipCode,
      toZipCode : this.toZipCode
    }
    this.api.POST(url, params).subscribe(
      resp => {
        Log.d('getData done ', resp);
        this.listData = resp.data.list_data;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  getBaseRate(item) {
    if (item.baseRate) return '$' + item.baseRate;
    return '';
  }

  getTransitDay(item) {
    switch (item.transitDay) {
      case 0: return 'Same Day';
      case 1: return '1 Day';
      default:
        return item.transitDay + ' Days';
    }
  }

  onBtnDownload = (item) => {
    return this.downloadAttachedFile(item);
  }

  formatDate(date) {
    return DateUtil.dateToString(date, 'MM/DD/YYYY, HH:mm');
  }
  

}