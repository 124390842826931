import { BaseComponent } from '@abstract/BaseComponent';
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { DateUtil } from '@services/date-utils';
import { Log } from '@services/log';
import { MasterData } from '@services/master.data';
import { Utils } from "@services/utils";

@Component({
  selector: "[upload-rate-file]",
  templateUrl: './list.html',
  styleUrls: ['../../list.scss', './list.scss'],
})
export class UploadRateFile extends BaseComponent {
  
  public isLoading = false;
  public isUploading = false;
  public listData = [];
  public limit = 3;
  public templateLink;

  constructor() {
    super();
  }

  ngOnInit(): void {
    let listTemplates = MasterData.getTemplateZipRate();
    if (Utils.isArrayNotEmpty(listTemplates)) {
      this.templateLink = listTemplates[0];
    }
    this.getData();
    super.ngOnInit();
  }

  private getData() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_ZIP_RATE}/list/files-uploaded`).subscribe(
      resp => {
        Log.d('getData done ', resp);
        this.listData = resp.data.list_data;
        this.isLoading = false;
      }, err => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  async onFileSelected(inputElement: HTMLInputElement) {
    const file = inputElement.files[0];
    inputElement.value = "";
    this.isUploading = true;
    let apiUrl = `${Const.APIURI_ZIP_RATE}/import`;
    let formData = new FormData();
    formData.append("uploadZipZateFile", file, file.name);
    let timeout = 10 * 60 * 1000 // 10 minutes
    this.api.postFormData(apiUrl, formData, {timeout}).subscribe(
      (resp) => {
        this.isUploading = false;
        this.showSuccess("Your file has been added successfully.");
        this.getData();
      },
      (err) => {
        this.isUploading = false;
        this.showErr(err);
      }
    );
  }

  onBtnDownload = (item) => {
    return this.downloadAttachedFile(item);
  }

  formatDate(date) {
    return DateUtil.dateToString(date, 'MM/DD/YYYY, HH:mm');
  }

  

}