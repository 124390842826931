<div class="no-padding list">
  <div class="flex no-padding bottom20">
    <div class="header-title flex1">Files Uploaded</div>
    <button nz-button nzType="defaul" class="left20"><a [href]="templateLink" target="_blank">Download Template</a></button>
    <ng-container *ngIf="requirePermissions([PermissionCode.zip_rate.update])">
      <button nz-button nzType="primary" class="left20" [disabled]="isUploading" (click)="inputFile.click()">
        <i nz-icon [nzType]="isUploading ? 'loading' : 'file-excel'" nzTheme="outline"></i>
        Upload Zip File
      </button>
      <input #inputFile type="file" hidden accept=".csv,.xlsx,.xls" (change)="onFileSelected(inputFile)" id="uploadZipFileInput">
    </ng-container>
  </div>

  <div class="list-body">
    <nz-table
      #nzTable nzBordered="true"
      [nzData]="listData" nzSize="small"
      [nzPageSize]="limit"
      [nzLoading]="isLoading"
      [nzLoadingIndicator]="tplLoading"
      [nzNoResult]="tplNoData"
      [nzSize]="'default'">
      <ng-template #tplNoData>
        <div *ngIf="!isLoading" class="nodata"><i nz-icon nzType="search" nzTheme="outline"></i>No data</div>
      </ng-template>
      <ng-template #tplLoading>
        <div class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
      </ng-template>
      <thead>
        <tr>
          <th>File Name</th>
          <th nzWidth="180px">Uploaded By</th>
          <th nzWidth="180px">Date & Time</th>
          <th nzWidth="180px">Zips Uploaded</th>
          <th nzWidth="180px">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of nzTable.data; let i = index">
          <td>{{item.name || ''}}</td>
          <td>{{getFullName(item.insert?.byUser)}}</td>
          <td>{{formatDate(item.insert?.when)}}</td>
          <td>{{item.zipTotal}}</td>
          <td><span (click)="onBtnDownload(item)" class="clickable" style="color: #1802D0;">Download</span></td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</div>
