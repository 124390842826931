import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { Utils } from "@services/utils";

@Component({
  selector: "[zip-rate]",
  templateUrl: './index.html',
  styleUrls: ['../detail.scss', './style.scss'],
})
export class ZipRate extends BaseComponent {
  
  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  

}