<div class="dashboard-child-container no-padding">
  <div class="form-detail">
    <div class="group">
      <div rate-calculator></div>
    </div>
    <div class="group">
      <div service-option-rate></div>
    </div>
    <div class="group">
      <div upload-rate-file></div>
    </div>
  </div>
</div>
