import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { SearchBoxModule } from '@libs/search-box/search-box.module';
import { DetailModule } from '../base/detail.module';
import { SelectBySearchingModule } from '@libs/select-client/module';
import { SharedModule } from '../shared/shared.module'
import { ZipRate } from '.';
import { RateCalculator } from './rate-calculator';
import { UploadRateFile } from './upload-rate-file/list';
import { ServiceOptionRate } from './service-option-rate';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzPaginationModule,
    NzSelectModule,
    NzButtonModule,
    NzInputModule,
    NzIconModule,
    NzFormModule,
    NzCollapseModule,
    NzCheckboxModule,
    NzRadioModule,
    NzDividerModule,
    NzDatePickerModule,
    SearchBoxModule,
    DetailModule,
    SelectBySearchingModule,
    NzToolTipModule,
    NzTypographyModule,
    NzPopoverModule,
    SharedModule,
  ],
  declarations: [
    ZipRate,
    RateCalculator,
    ServiceOptionRate,
    UploadRateFile,
  ],
  exports: [
  ],
  providers: [
  ]
})
export class ZipRateModule { }
